import moment from "moment";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { ProgressBar } from "primereact/progressbar";
import { Ripple } from "primereact/ripple";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { classNames } from "primereact/utils";
import React, { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AuthContext from "../../context";
import CateringService from "../../service/CateringService";
import CustomerService from "../../service/CustomerService";
import OrderService from "../../service/OrderService";
import { sortArray, sortArrayD } from "../../utilities/general";
import FilterOrderForm from "./FilterOrderForm";
import PrintOrderData from "./PrintOrderData";
import { customerAdmin } from "../../constants/auth";
import useOrderDataTaskUtil from "../../utilities/useOrderDataTaskUtil";
import { calculateOrderAmountPrice, calculateOrderPrice } from "../../utilities/menuItemUtil";
import { Dialog } from "primereact/dialog";

const OrdersDataTask = () => {
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [orderTableData, setOrderTableData] = useState([]);
    const [orderData, setOrderData] = useState();
    const [orderStockItemsData, setOrderStockItemsData] = useState([]);
    const [orderCountData, setOrderCountData] = useState();
    const [orderCountDataAll, setOrderCountDataAll] = useState();
    const [showPrintDialog, setShowPrintDialog] = useState(false);
    const [currentSelectedCustomer, setCurrentSelectedCustomer] = useState();
    const [currentSelectedMinDate, setCurrentSelectedMinDate] = useState();
    const [currentSelectedMaxDate, setCurrentSelectedMaxDate] = useState();
    const [customerData, setCustomerData] = useState();
    const [cateringData, setCateringData] = useState();
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [totalRecords, setTotalRecords] = useState(10);
    const [currentSearchData, setCurrentSearchData] = useState({});
    const [deleteOrderDialog, setDeleteOrderDialog] = useState(false);
    const [orderToDelete, setOrderToDelete] = useState(null)
    const [orderToPreview, setOrderToPreview] = useState(null)

    const toast = useRef(null);
    const dt = useRef(null);
    const orderService = new OrderService();
    const customerService = new CustomerService();
    const cateringService = new CateringService();

    const { t } = useTranslation();
    const authContext = useContext(AuthContext);
    const isCustomerUser = authContext?.roleDTO?.name == customerAdmin
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const { returnNormCountOfItems } = useOrderDataTaskUtil()
    const onPage = (event) => {
        let searchParams = { ...currentSearchData }
        searchParams.pageNumber = event.page
        searchParams.pageSize = event.rows
        searchOrders(searchParams)
    }
    const prepareDataForTable = (data) => {
        let tableData = [];
        tableData = data.map(x => {
            let allItems = "";
            let totalOrderPrice = 0; // Initialize total order price
            if (x?.menuItemSequences?.length > 0) {
                let arrayItems = sortArray(x.menuItemSequences, 'menuItemDTO.itemCategoryId');
                arrayItems.forEach((element, index) => {
                    const menuItemName = element.name;
                    const menuItemAmount = element.amount;
                    const menuItemPrice = element.price; // Get price of the item
                    const itemTotalPrice = menuItemAmount * menuItemPrice; // Calculate item total price

                    // Add item price to total order price
                    totalOrderPrice += itemTotalPrice;

                    if (menuItemName) {
                        let separator = ", ";
                        if (index === 0) {
                            separator = "";
                        }
                        // Append the menu item name, amount, and price
                        allItems += `${separator} ${menuItemAmount}x ${menuItemName}`;
                    }
                });
            }

            return {
                id: x.id,
                date: x.date,
                customerName: x.customerName,
                customerLocationName: x.customerLocationName,
                userFullName: x.userFullName,
                userPersonalNumber: x.userPersonalNumber,
                shift: x.shift,
                status: t(x.status),
                menuItems: allItems,  // Display name, amount, and price
                comment: x.comment,
                price: totalOrderPrice, // Use calculated total price
            };
        });
        return tableData;
    };


    const prepareDataForExport = (data) => {
        let tableData = [];
        tableData = data.map(x => {
            let allItems = "";
            let totalOrderPrice = 0; // Initialize total order price
            if (x?.menuItemSequences?.length > 0) {
                let arrayItems = sortArray(x.menuItemSequences, 'menuItemDTO.itemCategoryId');
                arrayItems.forEach((element, index) => {
                    const menuItemName = element?.name;
                    const menuItemAmount = element.amount;
                    const menuItemPrice = element.price;
                    const itemTotalPrice = menuItemAmount * menuItemPrice; // Calculate item total price

                    // Add item price to total order price
                    totalOrderPrice += itemTotalPrice;

                    if (menuItemName) {
                        let separator = ", ";
                        if (index === 0) {
                            separator = "";
                        }
                        // Append the menu item name and amount
                        allItems += `${separator}${menuItemAmount}x ${menuItemName}`;
                    }
                });
            }

            return {
                [t('date')]: x.date,
                [t('customerName')]: x.customerName,
                [t('location')]: x.customerLocationName,
                [t('userFullName')]: x.userFullName,
                [t('userPersonalNumber')]: x.userPersonalNumber,
                [t('shift')]: x.shift,
                [t('status')]: t(x.status),
                [t('menuItems')]: allItems,  // Display name and amount
                [t('comment')]: x.comment,
                [t('price')]: totalOrderPrice, // Use calculated total price
            };
        });
        return tableData;
    };

    const hideDeleteOrderDialog = () => {
        setDeleteOrderDialog(false);
    }
    const startDeleteOrder = (item) => {
        setOrderToDelete(item);
        setDeleteOrderDialog(true);
    }
    const deleteOrder = (id) => {
        orderService.delete(id).then(data => {
            setShowProgressBar(true)
            searchOrders(currentSearchData)
            toast.current.show({ severity: 'success', summary: t('successful'), detail: t('orderDeleted'), life: 3000 })

            // setMenuForCurrentDay(data.items)
        }).catch(() => {
            toast.current.show({ severity: 'error', summary: t('error'), detail: t('errorDuringOrderDelete'), life: 3000 })

        }).finally(() => {
            setShowProgressBar(false)
            setOrderToDelete()
            setDeleteOrderDialog(false)
            setOrderToPreview()
        })
    }
    const searchOrders = (searchData = {}, isExport = false, exportType) => {
        setOrderData()
        setOrderCountData()
        setOrderTableData([])
        setOrderCountDataAll()
        setOrderStockItemsData()
        setCustomerData()
        setCurrentSelectedCustomer()
        setCurrentSelectedMinDate()
        setCurrentSelectedMaxDate()
        if (searchData.customerId) {
            setCurrentSelectedCustomer(searchData.customerId)
        }
        let searchObject = {
            cateringId: authContext.cateringId
        }
        if (searchData.minDate) {
            setCurrentSelectedMinDate(moment(searchData.minDate).format("DD/MM/YYYY").toString())
            searchObject.minDate = moment(searchData.minDate).format("YYYY-MM-DD").toString()

        }
        if (searchData.maxDate) {
            setCurrentSelectedMaxDate(moment(searchData.maxDate).format("DD/MM/YYYY").toString())
            searchObject.maxDate = moment(searchData.maxDate).format("YYYY-MM-DD").toString()

        }
        if (searchData.user) {
            searchObject.userId = searchData.user
        }
        if (searchData.shift) {
            searchObject.shift = searchData.shift
        }
        if (searchData.status) {
            searchObject.status = searchData.status
        }
        searchObject = { ...searchData, ...searchObject }
        if (isExport || searchData?.type === 'amount' || searchData?.type === 'norm') {
            searchObject.pageSize = 100000
        }
        setShowProgressBar(true)
        orderService.search(searchObject).then(data => {
            setShowProgressBar(false)
            if (data?.items) {
                if (searchData.type === 'single') {
                    handleSingleOrderSearchResult(data, isExport, exportType)
                } else if (searchData.type === 'amount') {
                    handleAmountOrderSearchResult(data, isExport, exportType)

                } else if (searchData.type === 'norm') {
                    handleNormOrderSearchResult(data, isExport, exportType)

                }
            }
        }).catch(reason => {
            setShowProgressBar(false)
            toast.current.show({ severity: 'error', summary: t('error'), detail: t('errorDuringSearchOrder'), life: 3000 })
        })
    }
    const handleSingleOrderSearchResult = (data, isExport, exportType) => {
        if (isExport) {
            toast.current.show({ severity: 'success', summary: t('successful'), detail: t('dataExported'), life: 3000 })
            if (exportType == "excel") {
                exportExcel(data.items)

            }
            if (exportType == "pdf") {
                exportPdf(data.items)
            }
        } else {
            setFirst(data.pageNumber * data.pageSize);
            setRows(data.pageSize);
            setTotalRecords(data.itemCount)
            setOrderData(data.items)
            setOrderTableData(prepareDataForTable(data.items))
        }
    }
    const handleAmountOrderSearchResult = (data, isExport, exportType) => {
        if (isExport) {
            toast.current.show({ severity: 'success', summary: t('successful'), detail: t('dataExported'), life: 3000 })
            if (exportType == "excel") {
                exportExcel(data.items, true)

            }
            if (exportType == "pdf") {
                exportPdf(data.items, true)
            }
        } else {
            setOrderCountData(returnCountOfItems(data.items)?.data)
            setOrderCountDataAll(returnCountOfItems(data.items)?.count)
        }
    }
    const handleNormOrderSearchResult = (data, isExport, exportType) => {
        if (isExport) {
            toast.current.show({ severity: 'success', summary: t('successful'), detail: t('dataExported'), life: 3000 })
            if (exportType == "excel") {
                exportExcel(data.items, false, true)

            }
            if (exportType == "pdf") {
                exportPdf(data.items, false, true)
            }
        } else {
            setOrderStockItemsData(sortArrayD(returnNormCountOfItems(data?.items), 'amount'))
        }
    }
    const paginationTemplate = {
        layout: 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
        'PrevPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">{t("previous")}</span>
                    <Ripple />
                </button>
            )
        },
        'NextPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">{t("next")}</span>
                    <Ripple />
                </button>
            )
        },
        'PageLinks': (options) => {
            if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                const className = classNames(options.className, { 'p-disabled': true });

                return <span className={className} style={{ userSelect: 'none' }}>...</span>;
            }

            return (
                <button type="button" className={options.className} onClick={options.onClick}>
                    {options.page + 1}
                    <Ripple />
                </button>
            )
        },
        'RowsPerPageDropdown': (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: 'All', value: options.totalRecords }
            ];

            return <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />;
        }
    };
    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">{t("orders")}</h5>
        </div>
    );
    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{t("name")}</span>
                {rowData.customerName}
            </>
        );
    }
    const locationBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{t("location")}</span>
                {rowData.customerLocationName}
            </>
        );
    }
    const dateBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{t("name")}</span>
                {rowData.date}
            </>
        );
    }

    const usernameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{t("description")}</span>
                <span className={`status`}>{rowData.userFullName}</span>
            </>
        )
    }
    const commentBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{t("comment")}</span>
                <span className={`status`}>{rowData.comment}</span>
            </>
        )
    }
    const priceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{t("price")}</span>
                <span className={`status`}>{rowData.price} RSD</span>
            </>
        )
    }
    const priceAmountBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{t("price")}</span>
                <span className={`status`}>{rowData.amountPrice} RSD</span>
            </>
        )
    }
    const statusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{t("status")}</span>
                <span className={`status`}>{t(rowData.status)}</span>
            </>
        )
    }
    const menuItemsBodyTemplate = (rowData) => {
        return <>
            <span className="p-column-title">{t("description")}</span>
            <span className={`status`}>{rowData.menuItems}</span>
        </>
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions mt-2 text-center">
                <Button icon="pi pi-eye" className="p-button-rounded p-button-info mr-2" onClick={() => setOrderToPreview(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => startDeleteOrder(rowData)} />
            </div>
        );
    }
    const onPrint = () => {
        setShowProgressBar(true)
        customerService.get(currentSelectedCustomer).then((data) => {
            setCustomerData(data)
            if (isCustomerUser) {
                setShowProgressBar(false)
                setShowPrintDialog(true)
            } else {
                cateringService.get(authContext.cateringId).then((cateringData) => {
                    setCateringData(cateringData)
                    setShowProgressBar(false)
                    setShowPrintDialog(true)
                })
            }

        })
    }
    const exportPdf = (dataToExport, amount, norm) => {
        let cols = [
            { field: 'date', header: t('date') },
            { field: 'customerName', header: t('customerName') },
            { field: 'customerLocationName', header: t('location') },
            { field: 'userFullName', header: t('userFullName') },
            { field: 'userPersonalNumber', header: t('userPersonalNumber') },
            { field: 'shift', header: t('shift') },
            { field: 'status', header: t('status') },
            { field: 'menuItems', header: t('menuItems') },
        ];
        if (amount) {
            cols = [
                { field: 'name', header: t('name') },
                { field: 'amount', header: t('amount') },
            ];
        }
        if (norm) {
            cols = [
                { field: 'name', header: t('name') },
                { field: 'amount', header: t('amount') },
                { field: 'unit', header: t('unit') },
            ];
        }
        const exportColumns = cols.map(col => ({ title: col.header, dataKey: col.field }));
        import('jspdf').then(jsPDF => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);
                let data = []
                if (amount) {
                    data = returnCountOfItems(dataToExport)?.data
                } else if (norm) {
                    data = returnNormCountOfItems(dataToExport)
                } else {
                    data = prepareDataForTable(dataToExport)
                }
                doc.autoTable(exportColumns, data);
                doc.save(createFileName() + ".pdf");
            })
        })
    }
    const createFileName = () => {
        if (currentSearchData) {
            return t('orders') + " " + new Date().getTime()
        }
    }
    const exportExcel = (orderDataToExport, amount, norm) => {
        import('xlsx').then(xlsx => {
            let data = []
            if (amount) {
                data = returnCountOfItems(orderDataToExport)?.data
            } else if (norm) {
                data = returnNormCountOfItems(orderDataToExport)
            } else {
                data = prepareDataForExport(orderDataToExport)
            }
            const worksheet = xlsx.utils.json_to_sheet(data);
            var wscols = [
                { wch: 15 },
                { wch: 30 },
                { wch: 30 },
                { wch: 10 },
                { wch: 10 },
                { wch: 30 },
            ];
            worksheet["!cols"] = wscols;

            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'buffer' });
            saveAsExcelFile(excelBuffer, createFileName());
        });
    }
    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + EXCEL_EXTENSION);
            }
        });
    }
    const exportData = (searchParms, fileType) => {
        setCurrentSearchData(searchParms)
        searchOrders(searchParms, true, fileType)
    }
    const returnCountOfItems = (orderDataArray) => {
        let countData = [];
        let allMenuItemNames = [];
        let allMenuItems = [];

        if (orderDataArray?.length > 0) {
            // Flatten all menu items from the menuItemSequences
            orderDataArray.forEach(element => {
                allMenuItems = [...allMenuItems, ...element.menuItemSequences];
            });

            if (allMenuItems?.length > 0) {
                // Get unique item names
                allMenuItems.forEach(element => {
                    if (!allMenuItemNames.includes(element.menuItemDTO.name)) {
                        allMenuItemNames.push(element.menuItemDTO.name);
                    }
                });

                if (allMenuItemNames?.length > 0) {
                    countData = allMenuItemNames.map(name => {
                        const itemsWithSameName = allMenuItems.filter(item => item.menuItemDTO.name === name);
                        const category = itemsWithSameName[0]?.menuItemDTO.itemCategoryName;

                        // Calculate the total price and amount for this specific item
                        const amount = itemsWithSameName.reduce((sum, item) => sum + item.amount, 0);
                        const amountPrice = itemsWithSameName.reduce((sum, item) => sum + (item.amount * item.price), 0);

                        // Calculate the category price
                        const categoryItems = allMenuItems.filter(item => item.menuItemDTO.itemCategoryName === category);
                        const categoryPrice = categoryItems.reduce((sum, item) => sum + (item.amount * item.price), 0);

                        return {
                            name: name,
                            category: category,
                            categoryAmount: categoryItems.length,
                            categoryPrice: categoryPrice, // Total price for this category
                            amount: amount,               // Total amount of this item
                            amountPrice: amountPrice      // Total price for this item
                        };
                    });
                }
            }
        }

        return {
            data: countData,
            count: allMenuItems?.length
        };
    }



    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <Button label={t("export")} icon="pi pi-upload" className="p-button-help" onClick={exportCSV} /> */}
                <Button label={t("print")} icon="pi pi-print" className="p-button-help" onClick={() => onPrint()} disabled={orderTableData.length === 0} />
            </React.Fragment>
        )
    }
    const amountFooter = () => {
        return (
            <div className="flex">
                <div><span>{t('count')}</span>: {orderCountDataAll}
                    <div className="m-3 ml-4 font-bold">{t('amountPrice')} : {calculateOrderAmountPrice(orderCountData)} RSD</div>

                </div>
            </div>
        )
    }
    const groupHeaderTemplate = (data) => {
        return (
            <React.Fragment>
                <span className="image-text font-bold">{data?.category}</span>
            </React.Fragment>
        );
    }

    const groupFooterTemplate = (data) => {
        return (
            <div className="">
                <div className="m-3 ml-4 font-bold">{t('count')} : {data?.categoryAmount}</div>
                <div className="m-3 ml-4 font-bold">{t('amountPrice')} : {data?.categoryPrice} RSD</div>
            </div>
        );
    }
    const deleteOrderDialogFooter = (
        <>
            <Button label={t("no")} icon="pi pi-times" className="p-button-text" onClick={hideDeleteOrderDialog} />
            <Button label={t("yes")} icon="pi pi-check" className="p-button-text" onClick={() => deleteOrder(orderToDelete?.id)} />
        </>
    )
    return (
        <div className="grid crud-demo">
            {!showPrintDialog ? <div className="col-12">
                <div className="card col-12 p-4">
                    <FilterOrderForm searchOrders={searchOrders} setCurrentSearchData={setCurrentSearchData} exportData={exportData} />
                </div>
                <div className="card">
                    <Dialog visible={deleteOrderDialog} style={{ width: '450px' }} header={t("confirm")} modal footer={deleteOrderDialogFooter} onHide={hideDeleteOrderDialog}>
                        <div className="flex align-items-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {orderToDelete && <span>{t("areYouSureYouWantToDeleteOrder")} <b>{orderToDelete.date}</b>?</span>}
                        </div>
                    </Dialog>
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" right={rightToolbarTemplate}></Toolbar>
                    {showProgressBar && <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>}
                    {orderData && <DataTable ref={dt} value={orderTableData}
                        dataKey="id" paginator rows={rows} first={first} totalRecords={totalRecords} lazy className="datatable-responsive"
                        paginatorTemplate={paginationTemplate}
                        currentPageReportTemplate={t('defaultPaginationMessage')} onPage={onPage}
                        emptyMessage={t('noItemsFound')} header={header} responsiveLayout="scroll" >
                        {/* <Column sortable headerStyle={{ width: '3rem' }}></Column> */}
                        <Column field="date" header={t("date")} body={dateBodyTemplate} headerStyle={{ width: '13%', minWidth: '10rem' }}></Column>
                        <Column field="customerName" header={t("customerName")} body={nameBodyTemplate} headerStyle={{ width: '13%', minWidth: '10rem' }}></Column>
                        <Column field="customerLocationName" header={t("location")} body={locationBodyTemplate} headerStyle={{ width: '13%', minWidth: '10rem' }}></Column>
                        <Column field="userFullName" header={t("userFullName")} body={usernameBodyTemplate} headerStyle={{ width: '23%', minWidth: '10rem' }}></Column>
                        <Column field="shift" header={t("shift")} headerStyle={{ width: '8%', minWidth: '8rem' }}></Column>
                        <Column field="status" header={t("status")} body={statusBodyTemplate} headerStyle={{ width: '8%', minWidth: '8rem' }}></Column>
                        <Column field="menuItems" header={t("menuItems")} body={menuItemsBodyTemplate} headerStyle={{ width: '23%', minWidth: '10rem' }}></Column>
                        <Column field="comment" header={t("comment")} body={commentBodyTemplate} headerStyle={{ width: '23%', minWidth: '10rem' }}></Column>
                        <Column field="price" header={t("price")} body={priceBodyTemplate} headerStyle={{ width: '23%', minWidth: '10rem' }}></Column>
                        <Column body={actionBodyTemplate} headerStyle={{ width: '33%', minWidth: '10rem' }}></Column>
                    </DataTable>}
                    {orderCountData && <DataTable emptyMessage={t('noItemsFound')} sortMode="single" sortField="category" sortOrder={1} rowGroupFooterTemplate={groupFooterTemplate} rowGroupHeaderTemplate={groupHeaderTemplate} rowGroupMode="subheader" groupRowsBy="category" value={sortArrayD(orderCountData, 'amount')} responsiveLayout="scroll" footer={amountFooter}>
                        <Column field="name" header={t('name')}></Column>
                        <Column field="amount" header={t('amount')}></Column>
                        <Column field="amountPrice" header={t('amountPrice')} body={priceAmountBodyTemplate}></Column>
                    </DataTable>}
                    {orderStockItemsData && <DataTable emptyMessage={t('noItemsFound')} value={orderStockItemsData}>
                        <Column field="name" header={t('name')}></Column>
                        <Column field="amount" header={t('amount')}></Column>
                        <Column field="unit" header={t('unit')}></Column>
                    </DataTable>}
                </div>
            </div>
                : <div className="col-12"><PrintOrderData orderData={orderData} orderTableData={orderTableData} closePrint={() => setShowPrintDialog(false)} cateringData={cateringData} customerData={customerData} minDate={currentSelectedMinDate} maxDate={currentSelectedMaxDate} /></div>

            }
            {orderToPreview && <Dialog
                visible={orderToPreview}
                modal
                closable={false}
                showHeader={false}
                style={{ width: '45vw' }}
                breakpoints={{ '960px': '75vw', '640px': '100vw' }}
                footer={
                    <div className="flex border-top-1 pt-5 surface-border justify-content-end align-items-center">
                        <Button onClick={() => setOrderToPreview(false)} icon="pi pi-check" label="Ok" className="m-0"></Button>
                    </div>
                }>
                <section className="flex flex-column w-full mt-4">
                    <div className="flex w-full justify-content-between align-items-center mb-4">
                        <span className="font-semibold text-base text-600">
                            {t('order')} / <span className="text-900">{orderToPreview?.transactionNumber ?? orderToPreview?.id}</span>
                        </span>
                        <Button type="button" icon="pi pi-times text-sm" className="w-2rem h-2rem p-button-rounded p-button-secondary p-button-text text-600" onClick={() => setOrderToPreview(false)}></Button>
                    </div>
                    <div className="flex justify-content-between align-items-center w-full mb-4">
                        <p className="font-semibold text-xl mt-0 mb-0 text-900">
                            <span className="pi pi-calendar text-xl text-yellow-600"></span> {orderToPreview?.date}
                        </p>
                        <Button type="button" icon="pi pi-trash text-xl" className="w-2rem h-2rem p-button-rounded p-button-text surface-200 text-orange-600" onClick={()=>{
                            startDeleteOrder(orderToPreview)
                            setOrderToPreview()

                        }
                            }></Button>

                    </div>
                    <table>
                        <tr style={{ height: '2.5rem' }}>
                            <td className="font-medium text-base text-600">{t("name")}</td>
                            <td className="font-medium text-base text-900">{orderToPreview?.userFullName}</td>
                        </tr>
                        <tr style={{ height: '2.5rem' }}>
                            <td className="font-medium text-base text-600">{t("customer")}</td>
                            <td>
                                <span className="mr-2">{orderToPreview?.customerName}</span>
                            </td>
                        </tr>
                        <tr style={{ height: '2.5rem' }}>
                            <td className="font-medium text-base text-600">{t('shift')}</td>
                            <td>
                                <span className="p-tag">{orderToPreview?.shift}</span>
                            </td>
                        </tr>
                        <tr style={{ height: '2.5rem' }}>
                            <td className="font-medium text-base text-600">{t('menuItems')}</td>
                        </tr>


                    </table>
                    <div>
                        <ul className="list-none p-0 m-0">
                            <li className="flex flex-column md:flex-row md:align-items-center md:justify-content-between p-3 border-1 mb-3 bg-blue-50 border-yellow-500" style={{ borderRadius: '10px' }}>
                                <div>
                                    <div>
                                        <span className="inline-flex justify-content-center align-items-center w-2rem h-2rem border-circle border-1 border-yellow-200">
                                            <i className="pi pi-list text-yellow-700"></i>
                                        </span>
                                        <span className="text-yellow-700 font-bold ml-2">{orderToPreview?.menuItems}</span>
                                    </div>
                                    {/* <p className="text-yellow-700 mt-2 mb-0">Due <span className="font-medium">10/09/2021</span></p> */}
                                </div>
                                <div className="flex align-items-center justify-content-between md:justify-content-end mt-3 md:mt-0">
                                    <span className="bg-yellow-400 text-yellow-900 font-bold text-sm py-1 px-2" style={{ borderRadius: '10px' }}>{orderToPreview?.status}</span>
                                    <div className="text-right ml-3">
                                        <span className="text-yellow-700 font-bold">{orderToPreview?.price},RSD</span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="border-y-1 surface-border py-4 my-4">
                        <p className="font-medium text-lg text-700 mt-0">{t('comment')}</p>
                        <p className="text-base text-900 mt-0 mb-0">{orderToPreview?.comment}</p>
                    </div>

                </section>
            </Dialog>}
        </div>
    )

}
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(OrdersDataTask, comparisonFn);