import moment from "moment";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { SelectButton } from "primereact/selectbutton";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { calculateOrderPrice, getNumberOfMenuItemSequences, returnAllMenuItemsForOrder, sortMenuItemSequences } from "../../utilities/menuItemUtil";
import { Dialog } from "primereact/dialog";
import { Message } from "primereact/message";
import { useDispatch, useSelector } from "react-redux";
import { selectNewOrders } from "../../store/orderSelectors";
import { addShiftOrCommentToOrder, removeOrderItemForDate, resetOrders, updateOrderItemAmount } from "../../store/orderSlice";
import useOrderDataTaskUtil from "../../utilities/useOrderDataTaskUtil";
import { hideLoader, showLoader } from "../../store/uiSlice";
import { Toast } from "primereact/toast";
import OrderService from "../../service/OrderService";
import { selectCreditAmount } from "../../store/userSelectors";
const PublicCheckoutPage = (props) => {
    const { orderConfiguration, showErrorMessage, goToPayment } = props
    const [paymentMethod, setPaymentMethod] = useState()
    const [creditValue, setCreditValue] = useState(0)
    const [showCreditDialog, setShowCreditDialog] = useState(false)
    const [saved, setSaved] = useState(false)
    let orders = useSelector(selectNewOrders)
    const creditAmount = useSelector(selectCreditAmount);

    let orderDataUtil = useOrderDataTaskUtil()
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const toast = useRef(null);
    const orderService = new OrderService();
    let maxCreditValue = creditAmount
    let creditAllowed = false
    if (creditAmount && calculateOrderPrice(returnAllMenuItemsForOrder(orders)) < creditAmount) {
        creditAllowed = true
        maxCreditValue = calculateOrderPrice(returnAllMenuItemsForOrder(orders))
    }
    const paymentMethodOptions = [
        { img: 'assets/layout/images/credit-card.png', value: 'card', disabled: orderConfiguration?.orderPaymentType == "NO_PAYMENT" },
        { img: 'assets/layout/images/cash.png', value: 'cash', disabled: orderConfiguration?.orderPaymentType == "WITH_PAYMENT" },
        { img: 'assets/layout/images/voucher.png', value: 'voucher', disabled: orderConfiguration?.orderPaymentType == "NO_PAYMENT" || !creditAllowed },
    ];
    useEffect(() => {
        if (orderConfiguration?.orderPaymentType == "WITH_PAYMENT") {
            setPaymentMethod('card')
        } else if (orderConfiguration?.orderPaymentType == "OPTIONAL") {

        } else {
            setPaymentMethod('cash')
        }

    }, [])

    const saveChanges = () => {
        dispatch(showLoader())
        // setShowProgressBar(true)
        // setDisableSaveButton(true)
        let dataForServer = orders.map(x => ({
            ...x, date: moment(x.date, "DD/MM/YYYY").format("YYYY-MM-DD").toString(), status: "CREATED"
        }))
        orderService.save(dataForServer).then(data => {
            setSaved(true)
            dispatch(resetOrders())
            // setActiveCatering(data?.[0]?.id)
            // setMenusForCurrentWeek()
            // setOrders([])
            // getActiveMenus(firstDayOfWeek, lastDayOfWeek)
            // setShowProgressBar(false)
            // setDisableSaveButton(false)
            toast.current.show({ severity: 'success', summary: t('successful'), detail: t('orderSaved'), life: 3000 })
        }).catch(reason => {
            // setDisableSaveButton(false)
            toast.current.show({ severity: 'error', summary: t('error'), detail: t('errorDuringorderSave'), life: 3000 })
        }).finally(() => {
            dispatch(hideLoader())
        })
    }
    const saveWithChangesWithCredit = () => {
        dispatch(showLoader())
        // setShowProgressBar(true)
        // setDisableSaveButton(true)
        let dataForServer = {
            orderDTOList: orders.map(x => ({
                ...x, date: moment(x.date, "DD/MM/YYYY").format("YYYY-MM-DD").toString(), status: "PAID"
            })),
            creditAmount: creditValue,
            amount: creditValue,
            userId: orders?.[0].userId
        }
        orderService.saveWithCredit(dataForServer).then(data => {
            setSaved(true)
            dispatch(resetOrders())
            setCreditValue(0)
            setTimeout(() => {
                window.location = "/"
            }, 3000);
            // setActiveCatering(data?.[0]?.id)
            // setMenusForCurrentWeek()
            // setOrders([])
            // getActiveMenus(firstDayOfWeek, lastDayOfWeek)
            // setShowProgressBar(false)
            // setDisableSaveButton(false)
            toast.current.show({ severity: 'success', summary: t('successful'), detail: t('orderSaved'), life: 3000 })
        }).catch(reason => {
            // setDisableSaveButton(false)
            toast.current.show({ severity: 'error', summary: t('error'), detail: t('errorDuringorderSave'), life: 3000 })
        }).finally(() => {
            dispatch(hideLoader())
        })
    }
    const changeAmountOfItemSequence = (date, item) => {
        if (getNumberOfMenuItemSequences(orders, date, item.itemCategoryName) >= orderConfiguration.maxNumberOfOrdersForCategory) {
            showErrorMessage(item, orderConfiguration.maxNumberOfOrdersForCategory)
        } else {
            dispatch(removeOrderItemForDate({ date: date, itemId: item?.id }))
        }
    }
    const onPlaceOrder = () => {
        if (paymentMethod == "cash") {
            saveChanges()
        } else if (paymentMethod == "voucher") {
            saveWithChangesWithCredit()
        }
        else {
            goToPayment(calculateOrderPrice(returnAllMenuItemsForOrder(orders)), null)
        }
    }
    const shiftOptions = () => {
        let options = []
        if (orderConfiguration?.numberOfShifts > 1) {
            for (let index = 0; index < orderConfiguration?.numberOfShifts; index++) {
                let value = index + 1
                options.push({
                    label: index + 1, value: value.toString()
                })
            }
        }
        return options;
    }
    const onShiftChange = (e, date) => {
        dispatch(addShiftOrCommentToOrder({ date: date, shift: e.value, comment: null }))
    }
    const onCommentChange = (e, date) => {
        dispatch(addShiftOrCommentToOrder({ date: date, shift: null, comment: e.target.value }))
    }
    const returnItemsByOrder = () => {
        let items = []
        if (orders?.length > 0) {
            items = orders.map(x => {
                let day = moment(x.date, "DD/MM/YYYY").format('dddd')
                if (x?.menuItemSequences?.length > 0) {
                    return <div className="flex">
                        <div className="flex flex-column align-items-center ml-3" style={{ width: '2rem' }}>
                            <span className="bg-green-500 text-0 flex align-items-center justify-content-center border-circle" style={{ minWidth: '3rem', minHeight: '3rem' }}>
                                <i className="pi pi-calendar text-xl"></i>
                            </span>

                            <div className="h-full border-dashed border-1 surface-border" style={{ minHeight: '15rem' }}>
                            </div>


                        </div>
                        <div className="ml-0 lg:ml-5 p-2 flex-auto">
                            <div className="mb-3">
                                <span className="text-900 text-xl block ml-2">{t(day)} {x.date}  {x.isEdit && <span className="text-600 ml-2 mt-2"><i className="pi pi-info-circle mr-2"></i>{t('edited')}</span>
                                }</span>

                            </div>
                            {returnItemsByCategory(x.menuItemSequences, x.date)}
                            <div className="flex flex-column md:flex-row justify-content-around">
                                <div className="field mb-4">
                                    <label htmlFor="shift" className="font-medium text-900">{t('shift')}</label>
                                    <SelectButton options={shiftOptions()} id="shift" itemTemplate={shiftTemplate} onChange={(e) => onShiftChange(e, x.date)} value={orderDataUtil.getShiftByDate(orders, x.date)} />

                                </div>
                                <div className="field mb-4 mt-4">
                                    <InputTextarea placeholder={t('comment')} id="comment" type="text" rows={5} autoResize onChange={(e) => onCommentChange(e, x.date)} value={orderDataUtil.getCommentByDate(orders, x.date)} name={"comment" + x.date} className="w-20rem" />
                                </div>

                            </div>
                        </div>


                    </div>
                }
            })
        }
        return items
    }
    const returnItemsByCategory = (menuItemSequences, date) => {
        let items
        if (menuItemSequences?.length) {
            items = sortMenuItemSequences(menuItemSequences).map(x => {
                let singleItem = x.menuItemDTO;
                return <div className="p-2 flex flex-column lg:flex-row flex-wrap lg:align-items-center my-3 md:my-0">
                    <img src={singleItem.imagePreview ? "data:image/png;base64," + singleItem.imagePreview : "assets/layout/images/blank_menu_item.jpg"} className="h-5rem w-8rem mb-3 lg:mb-0 flex-shrink-0" alt="product" />
                    <div className="flex-auto lg:ml-3">
                        <div className="flex align-items-center justify-content-between mb-3">
                            <span className="text-900 font-medium">{x.name}</span>
                            <span className="text-900 font-medium">{x.price},00 RSD</span>
                        </div>
                        <div className="text-600 text-sm mb-3">{singleItem.itemCategoryName}</div>
                        <div className="flex flex-auto justify-content-between align-items-center">
                            <InputNumber showButtons buttonLayout="horizontal" min="1" max={orderConfiguration.maxNumberOfOrdersForCategory} inputClassName="w-2rem text-center p-0 border-none surface-50" value={x.amount ?? 1} onChange={(e) => changeAmountOfItemSequence(date, singleItem)}
                                className="border-none border-round" decrementButtonClassName="surface-50 p-button-text text-600 hover:text-primary" incrementButtonClassName="p-button-text surface-50 text-600 hover:text-primary py-1 px-1" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
                            <span className="text-600 cursor-pointer" onClick={() => dispatch(removeOrderItemForDate({ date: date, itemId: singleItem?.id }))}><i className="pi pi-trash mr-2"></i>{t('delete')}</span>
                        </div>
                    </div>
                </div>
            })
        }
        return items
    }
    const paymentMethodTemplate = (option) => {
        return <img src={option.img} alt={"langImg"} className='mx-2' width="40" height="auto" />;

    }
    const shiftTemplate = (option) => {
        return <span className="text-xl"><i className="pi pi-clock mr-2" />{option.label}</span>;

    }
    const creditDialogFooter = (
        <div className='grid text-right'>
            <div className='col-offset-5 col-3'>
                <Button type="button" label={t('cancel')} icon="pi pi-times" className="p-button-text col-6" onClick={() => {
                    setShowCreditDialog(false)
                    setCreditValue(0)
                }} />
            </div>
            <div className='col-3'>
                <Button label={t('save')} icon="pi pi-check" className="p-button-text col-6" onClick={() => setShowCreditDialog(false)
                } />
            </div>
        </div>
    );
    const onPaymentMethodChange = (e) => {
        if (e.value == "voucher") {
            setCreditValue(calculateOrderPrice(returnAllMenuItemsForOrder(orders)))
        } else {
            setCreditValue(0)
        }
        setPaymentMethod(e.value)
    }

    return (
        <div className="surface-50 px-4 py-8 md:px-6 lg:px-8">
            <Toast ref={toast} />

            <Dialog header={t('chooseCredit')} footer={creditDialogFooter} visible={showCreditDialog} style={{ width: '30vw' }} onHide={() => { if (!showCreditDialog) return; setShowCreditDialog(false); }}>
                <Message severity="info" text={t('creditMessage') + " " + creditAmount} className='w-full' />
                <InputNumber value={creditValue} onChange={(e) => e.value <= maxCreditValue ? setCreditValue(e.value) : setCreditValue(maxCreditValue)} max={maxCreditValue}
                    className="border-none border-round mt-2" />
            </Dialog>
           { !saved && <div className="text-900 font-medium text-3xl mb-5">{t('yourOrderIsAlmostDone')}</div>}
            <div className="grid -mr-3 -ml-3">
                <div className="col-12 lg:col-9 p-0 md:p-3">
                    <div className="px-0 py-4 lg:px-4 surface-card border-round shadow-2">
                        {saved && <div className="surface-0 text-700 text-center ">
                            <div className="text-green-600 font-bold mb-3"><i className="text-7xl pi pi-check-circle"></i></div>
                            <div className="text-900 font-bold text-5xl mb-3">{t('orderSuccessfullySaved')}</div>
                        </div>}
                        {orders?.length > 0 ? returnItemsByOrder() : !saved && <Message severity="info" text={t('noItems')} className='w-full' />}

                    </div>
                </div>
                {!saved && <div className="col-12 lg:col-3 p-3">
                    <div className="surface-card p-4 border-round shadow-2">
                        <div className="border-bottom-1 surface-border pb-4">
                            <span className="text-900 text-xl block">{t('orderSummary')}</span>
                        </div>
                        <div className="border-bottom-1 surface-border my-3 py-2">
                            <div className="flex justify-content-between mb-3">
                                <span className="text-900">{t('price')}</span>
                                <span className="text-900">{calculateOrderPrice(returnAllMenuItemsForOrder(orders), creditValue)},00 RSD</span>
                            </div>
                            {creditAmount > 0 && <div className="flex justify-content-between mb-3">
                                <span className="text-900"
                                // onClick={() => setShowCreditDialog(true)}
                                > {t('credit')}</span>

                                <span className="text-blue-500">{creditValue},00 RSD</span>
                            </div>}

                        </div>
                        <div className="border-bottom-1 surface-border my-3 py-2">
                            <div className="flex justify-content-between mb-3">
                                <span className="text-900 font-medium">{t('total')}</span>
                                <span className="text-900 font-bold">{calculateOrderPrice(returnAllMenuItemsForOrder(orders), creditValue)},00 RSD</span>
                            </div>
                        </div>
                        {
                            (orderConfiguration?.orderPaymentType == "WITH_PAYMENT" || orderConfiguration?.orderPaymentType == "OPTIONAL") && <label htmlFor="paymentMethod">{t('choosePaymentMethod')}</label>
                        }

                        {(orderConfiguration?.orderPaymentType == "WITH_PAYMENT" || orderConfiguration?.orderPaymentType == "OPTIONAL") && <SelectButton options={paymentMethodOptions} value={paymentMethod} itemTemplate={paymentMethodTemplate} onChange={(e) => onPaymentMethodChange(e)} />}
                        {creditAmount > 0 && <Message severity="info" text={t('creditMessage') + " " + creditAmount} className='w-full mt-2' />}

                        <Button disabled={!paymentMethod} className="surface-400 border-none bg-primary w-full mt-3" label={paymentMethod == "card" ? t('goToPayment') : t('placeOrder')} onClick={() => onPlaceOrder()} />
                    </div>
                </div>}
            </div>

            <div className="surface-section px-4 py-8 md:px-6 lg:px-8">
                <div className="flex justify-content-evenly flex-wrap">
                    <div className="p-3">
                        <img src="assets/layout/images/payment/ms.png" alt="Image" height="40" />
                    </div>
                    <div className="p-3">
                        <img src="assets/layout/images/payment/mc.png" alt="Image" height="40" />
                    </div>
                    <div className="p-3">
                        <img src="assets/layout/images/payment/visa.png" alt="Image" height="40" />
                    </div>
                    <div className="p-3">
                        <img src="assets/layout/images/payment/dina.jpg" alt="Image" height="40" />
                    </div>
                    <div className="p-3">
                        <img src="assets/layout/images/payment/visa-secure.png" alt="Image" height="40" />
                    </div>
                    <div className="p-3">
                        <img src="assets/layout/images/payment/id.png" alt="Image" height="40" />
                    </div>
                    <div className="p-3">
                        <img src="assets/layout/images/payment/rf.png" alt="Image" height="40" />
                    </div>
                </div>
            </div>

        </div >
    );
}

export default PublicCheckoutPage;