import moment from "moment";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { ProgressBar } from "primereact/progressbar";
import { Ripple } from "primereact/ripple";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { classNames } from "primereact/utils";
import React, { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AuthContext from "../../context";
import CateringService from "../../service/CateringService";
import CustomerService from "../../service/CustomerService";
import OrderService from "../../service/OrderService";
import { isMobile, sortArray, sortArrayD } from "../../utilities/general";
import FilterOrderForm from "./FilterOrderForm";
import PrintOrderData from "./PrintOrderData";
import { customerAdmin } from "../../constants/auth";
import useOrderDataTaskUtil from "../../utilities/useOrderDataTaskUtil";
import { calculateOrderAmountPrice, calculateOrderPrice, groupMenuByCategory, truncateDescriptionString } from "../../utilities/menuItemUtil";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { InputNumber } from "primereact/inputnumber";
import { searchMenuParams } from "../../service/serviceObjects";
import MenuService from "../../service/MenuService";
import { Controller, useForm } from "react-hook-form";
import { Calendar } from "primereact/calendar";
import OrderPreviewComponent from "./OrderPreviewComponent";
import { Message } from "primereact/message";
const GroupOrdersDataTask = () => {
    let currentDate = moment();

    const [showProgressBar, setShowProgressBar] = useState(false);
    const [deleteOrderDialog, setDeleteOrderDialog] = useState(false);
    const [orderToDelete, setOrderToDelete] = useState(null)
    const [orderToPreview, setOrderToPreview] = useState(null)
    const [orderTableData, setOrderTableData] = useState([]);
    const [orderData, setOrderData] = useState();
    const [visibleNewOrder, setVisibleNewOrder] = useState(false);
    const [menuForCurrentDay, setMenuForCurrentDay] = useState([]);
    const [categorizedMenuForCurrentDay, setCategorizedMenuForCurrentDay] = useState([]);
    const [currentDay, setCurrentDay] = useState(moment(currentDate).format("DD/MM/YYYY"));
    const [newOrderData, setNewOrderData] = useState();
    const [cateringId, setCateringId] = useState();
    const [date, setDate] = useState();

    const toast = useRef(null);
    const dt = useRef(null);
    const orderService = new OrderService();
    const customerService = new CustomerService();
    const cateringService = new CateringService();
    const menuService = new MenuService();

    const { t } = useTranslation();
    const authContext = useContext(AuthContext);
    const { control, formState: { errors }, handleSubmit, setValue, getValues, reset } = useForm();
    const submitFunction = (data) => {

        let menuItemSequences = []
        Object.keys(data).map(x => {
            let id = x.split(";")?.[0]
            let name = x.split(";")?.[1]
            let price = x.split(";")?.[2]
            if (data[x] > 0) {
                menuItemSequences.push({
                    name: name,
                    price: parseInt(price),
                    amount: data[x],
                    menuItemDTO: {
                        id: parseInt(id)
                    }
                })
            }

        })
        let order = [{
            cateringId: cateringId,
            customerId: authContext?.customerId,
            date: moment(currentDay, "DD/MM/YYYY").format("YYYY-MM-DD").toString(),
            menuItemSequences: menuItemSequences,
            shift: 1,
            status: "CREATED",
            groupOrder: true
        }]
        saveOrder(order)
    }
    const saveOrder = (order) => {
        let dataForServer = order
        setShowProgressBar(true)
        // setDisableSaveButton(true)
        // let dataForServer = orders.map(x => ({
        //     ...x, date: moment(x.date, "DD/MM/YYYY").format("YYYY-MM-DD").toString(), status: "CREATED", shift: shifts?.[x.date] ?? 1, comment: comments?.[x.date]
        // }))
        orderService.save(dataForServer).then(data => {
            reset()
            setVisibleNewOrder(false)
            toast.current.show({ severity: 'success', summary: t('successful'), detail: t('orderSaved'), life: 3000 })
        }).catch(reason => {
            // setDisableSaveButton(false)
            toast.current.show({ severity: 'error', summary: t('error'), detail: t('errorDuringorderSave'), life: 3000 })
        }).finally(() => {
            setShowProgressBar()
            // setDisplayLoader(false)

        })
    }
    const deleteOrder = (id) => {
        orderService.delete(id).then(data => {
            setShowProgressBar(true)
            searchOrders()
            toast.current.show({ severity: 'success', summary: t('successful'), detail: t('orderDeleted'), life: 3000 })

            // setMenuForCurrentDay(data.items)
        }).catch(() => {
            toast.current.show({ severity: 'error', summary: t('error'), detail: t('errorDuringOrderDelete'), life: 3000 })

        }).finally(() => {
            setShowProgressBar(false)
            setOrderToDelete()
            setDeleteOrderDialog(false)
            setOrderToPreview()
        })
    }
    const onNewOrder = () => {
        getActiveMenus(currentDay, currentDay)
    }
    const getActiveMenus = (first, last) => {
        let searchObject = {
            ...searchMenuParams,
            minDate: moment(first, "DD/MM/YYYY").format("YYYY-MM-DD").toString(),
            maxDate: moment(last, "DD/MM/YYYY").format("YYYY-MM-DD").toString(),
            customerId: authContext?.customerId
        }
        if (authContext?.roleDTO.name.includes('catering') && authContext.cateringId) {
            searchObject.cateringId = authContext.cateringId
        }
        setShowProgressBar(true)
        menuService.search(searchObject).then(data => {
            setTimeout(() => {
                setShowProgressBar(false)
            }, 500);
            setMenuForCurrentDay(data?.items?.[0])
            setCateringId(data?.items?.[0]?.cateringId)
            if (data?.items?.[0]?.menuItemDTOList) {
                setCategorizedMenuForCurrentDay(groupMenuByCategory(data?.items?.[0]?.menuItemDTOList))
            }
            setVisibleNewOrder(true)
        })
    }
    const prepareDataForTable = (data) => {
        let tableData = [];
        tableData = data.map(x => {
            let allItems = "";
            let totalOrderPrice = 0; // Initialize total order price
            if (x?.menuItemSequences?.length > 0) {
                let arrayItems = sortArray(x.menuItemSequences, 'menuItemDTO.itemCategoryId');
                arrayItems.forEach((element, index) => {
                    const menuItemName = element.name;
                    const menuItemAmount = element.amount;
                    const menuItemPrice = element.price; // Get price of the item
                    const itemTotalPrice = menuItemAmount * menuItemPrice; // Calculate item total price

                    // Add item price to total order price
                    totalOrderPrice += itemTotalPrice;

                    if (menuItemName) {
                        let separator = ", ";
                        if (index === 0) {
                            separator = "";
                        }
                        // Append the menu item name, amount, and price
                        allItems += `${separator} ${menuItemAmount}x ${menuItemName}`;
                    }
                });
            }

            return {
                id: x.id,
                date: x.date,
                customerName: x.customerName,
                customerLocationName: x.customerLocationName,
                userFullName: x.userFullName,
                userPersonalNumber: x.userPersonalNumber,
                shift: x.shift,
                status: t(x.status),
                menuItems: allItems,  // Display name, amount, and price
                comment: x.comment,
                price: totalOrderPrice, // Use calculated total price
            };
        });
        return tableData;
    };
    const searchOrders = (searchData = {}) => {
        setOrderData()
        setOrderTableData([])
        let searchObject = {
            cateringId: authContext.cateringId,
            customerId: authContext.customerId,
            groupOrder: true
        }

        if (searchData.shift) {
            searchObject.shift = searchData.shift
        }
        if (searchData.status) {
            searchObject.status = searchData.status
        }
        searchObject = { ...searchData, ...searchObject }
        setShowProgressBar(true)
        orderService.searchGroup(moment(currentDay, "DD/MM/YYYY").format("YYYY-MM-DD").toString(), authContext?.customerId).then(data => {
            setShowProgressBar(false)
            if (data) {
                setOrderToPreview(data)
            }
        }).catch(reason => {
            setShowProgressBar(false)
            toast.current.show({ severity: 'error', summary: t('error'), detail: t('errorDuringSearchOrder'), life: 3000 })
        })
    }
    const hideDeleteOrderDialog = () => {
        setDeleteOrderDialog(false);
    }
    const startDeleteOrder = (item) => {
        setOrderToDelete(item);
        setDeleteOrderDialog(true);
    }
    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Calendar value={date} dateFormat="dd/mm/yy" className="mr-5" minDate={new Date()} onChange={(e) => {
                        setDate(e.value)
                        setCurrentDay(moment(e.value).format("DD/MM/YYYY"))
                    }} />
                    <Button disabled={!date} label={t("new")} icon="pi pi-plus" className="p-button-success mr-2" onClick={() => onNewOrder()} />
                    <Button disabled={!date} label={t("search")} icon="pi pi-search" className="p-button-success mr-2" onClick={() => searchOrders()} />

                </div>
            </React.Fragment>
        )
    }

    const deleteOrderDialogFooter = (
        <>
            <Button label={t("no")} icon="pi pi-times" className="p-button-text" onClick={hideDeleteOrderDialog} />
            <Button label={t("yes")} icon="pi pi-check" className="p-button-text" onClick={() => deleteOrder(orderToDelete?.id)} />
        </>
    )
    const isEditEnabled = (currentElement) => {
        if (currentElement?.dateActiveFrom && currentElement?.dateActiveTo) {
            return moment().utc().isBetween(moment(currentElement.dateActiveFrom).utc(), moment(currentElement.dateActiveTo).utc())
        }
        return false
    }
    let editEnabled = isEditEnabled(menuForCurrentDay) || authContext?.roleDTO.name.includes('admin')

    const returnOneMenuItem = (singleElement, mini, isEdit) => {
        return <div className="flex flex-column lg:flex-row flex-wrap lg:align-items-center py-2 px-2 mt-3 border-bottom-1 surface-border">
            <img src={singleElement.image ? "data:image/png;base64," + singleElement.image : "assets/layout/images/blank_menu_item.jpg"} className="w-8rem h-8rem flex-shrink-0 mb-3" alt="checkoutform-1-2" style={{ resize: "horizontal", objectFit: 'cover' }} />
            <div className="flex-auto lg:ml-3">
                <div className="flex align-items-center justify-content-between mb-3">
                    <span className="text-900 font-medium">{singleElement?.name}</span>
                </div>
                <div className="text-600 text-sm mb-3"> {truncateDescriptionString(singleElement?.description, 20)}
                </div>
                <div className="flex flex-auto justify-content-between align-items-center">
                    <span className="text-900 font-bold mr-2">{singleElement?.price},00RSD</span>
                    <Controller name={singleElement?.id + ";" + singleElement?.name + ";" + singleElement?.price} control={control} render={({ field, fieldState }) => (
                        <InputNumber id={field.name} {...field} showButtons buttonLayout="horizontal" min="0" onChange={(e) => field.onChange(e.value)} inputClassName="w-2rem text-center py-2 px-1 border-transparent" value={0}
                            className="border-1 surface-border border-round" decrementButtonClassName="p-button-text text-600 hover:text-primary py-1 px-1" incrementButtonClassName="p-button-text text-600 hover:text-primary py-1 px-1" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
                        // <MultiSelect id={field.name} {...field} onChange={(event) => {
                        //     field.onChange(event)
                        //     onCustomerLocationChange(event)
                        // }} options={customersLocationsOptions} className={classNames({ 'p-invalid': fieldState.invalid })} display="chip" />
                    )} />


                </div>
            </div>
        </div>

        // <div className="col-12 md:col-6 lg:col-3 mb-5 md:mb-0">
        //     <div className="p-2">
        //         <div className="relative mb-3">
        //             <img src={singleElement.image ? "data:image/png;base64," + singleElement.image : "assets/layout/images/blank_menu_item.jpg"} className={mini ? "h-6rem w-full" : "h-10rem w-full"} style={{ resize: "horizontal", objectFit: 'cover' }} />
        //             {(singleElement.isVegetarian || singleElement.isVegan || singleElement.isLenten) && <span className="bg-gray-50 text-gray-200 font-bold px-2 py-1 absolute" style={{ borderRadius: '1rem', left: '1rem', top: '1rem' }}>
        //                 <div className='flex justify-content-left align-items-center h-3rem min-h-3rem'>
        //                     <span className={`product-badge status`}>{singleElement.isVegetarian ? <img src={"assets/layout/images/vegetarian.jpg"} alt={singleElement.name} className="h-2rem" />
        //                         : <></>}</span>
        //                     <span className={`product-badge status`}>{singleElement.isVegan ? <img src={"assets/layout/images/vegan.png"} alt={singleElement.name} className="h-2rem" />
        //                         : <></>}</span>
        //                     <span className={`product-badge status`}>{singleElement.isLenten ? <img src={"assets/layout/images/posno.svg"} alt={singleElement.name} className="h-2rem" />
        //                         : <></>}</span>
        //                 </div>
        //             </span>}
        //             <span className="bg-gray-50 text-gray-200 font-bold px-2 py-1 absolute" style={{ borderRadius: '1rem', right: '1rem', bottom: '1rem' }}>
        //                 <span className="text-600">{singleElement?.price},00 RSD</span>

        //             </span>


        //         </div>
        //         <div className="text-900 font-medium h-3rem" style={{ fontSize: singleElement?.name?.length > 20 ? '1.2em' : "1.5em" }}>{singleElement.name}</div>
        //         <div className="my-2 h-2rem" >
        //             <span className="mr-3 h-full w-full" style={isMobile() ? { fontSize: '1em' } : { fontSize: "1em" }}>
        //                 {truncateDescriptionString(singleElement?.description)}
        //             </span>
        //             {/* <span className="text-500">12 reviews</span> */}
        //         </div>
        //     </div>
        // </div>
    }
    let newOrderDialogContent = <Message severity="info" text={t('noOrder')} className='w-full m-2' />
    if (categorizedMenuForCurrentDay?.length > 0) {
        newOrderDialogContent = (
            <div className='grid col-12'>
                <form onSubmit={handleSubmit(submitFunction)} className="p-fluid col-12 px-5">

                    {
                        categorizedMenuForCurrentDay.map(x => {

                            return <div className='grid col-12 w-full' style={{ filter: !editEnabled ? 'opacity(30%) blur(2px)' : 'none' }}>

                                <div className='col-12'>
                                    <div className="mt-1 font-bold text-left" style={isMobile() ? { fontSize: '7.2vw' } : { fontSize: '1.2vw' }}>{x.categoryName}</div>
                                </div>
                                <div className='col-12 w-full'>
                                    <div className="grid -mt-3 -ml-3 -mr-8">

                                        {x.elements.map(y => {

                                            return returnOneMenuItem(y)
                                        })
                                        }
                                    </div>
                                </div>
                            </div>


                        })
                    }
                    <div className="col-12 text-right">
                        <Button className="w-10rem" label={t("save")} />
                    </div>
                </form>
            </div>
        )
    }

    return (
        <div className="grid crud-demo">
            <Dialog visible={visibleNewOrder} onHide={() => setVisibleNewOrder(false)} modal breakpoints={{ '960px': '75vw', '640px': '95vw' }} style={{ width: '80vw' }}
                header={() => <span className="font-medium text-2xl text-900">{currentDay}</span>} blockScroll>
                {newOrderDialogContent}

            </Dialog>
            <Dialog visible={deleteOrderDialog} style={{ width: '450px' }} header={t("confirm")} modal footer={deleteOrderDialogFooter} onHide={hideDeleteOrderDialog}>
                <div className="flex align-items-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {orderToDelete && <span>{t("areYouSureYouWantToDeleteOrder")} <b>{orderToDelete.date}</b>?</span>}
                </div>
            </Dialog>
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
                    {showProgressBar && <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>}
                </div>
            </div>
            {orderToPreview && <OrderPreviewComponent orders={orderToPreview} />
            }
        </div>
    )
}
export default GroupOrdersDataTask
