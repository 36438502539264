import axios from 'axios';
import { BLCUrl, defaultHeader } from './serviceUtil';
const resourceName = "/order"

export default class OrderService {
    save(requestBody) {
        return axios.post(BLCUrl + resourceName, requestBody, { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
    update(id, requestBody) {
        return axios.put(BLCUrl + resourceName + "/" + id, requestBody, { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
    search(searchData) {
        let searchParams = "?"
        if (searchData?.cateringId) {
            searchParams += "&cateringId=" + searchData.cateringId
        }
        if (searchData?.customerId) {
            searchParams += "&customerId=" + searchData.customerId
        }
        if (searchData?.locationId) {
            searchParams += "&locationId=" + searchData.locationId
        }
        if (searchData?.userId) {
            searchParams += "&userId=" + searchData.userId
        }
        if (searchData?.shift) {
            searchParams += "&shift=" + searchData.shift
        }
        if (searchData?.status) {
            searchParams += "&status=" + searchData.status
        }
        if (searchData?.minDate) {
            searchParams += "&minDate=" + searchData.minDate
        }
        if (searchData?.maxDate) {
            searchParams += "&maxDate=" + searchData.maxDate
        }
        if (searchData?.groupOrder) {
            searchParams += "&groupOrder=" + searchData.groupOrder
        }
        if (searchData?.pageSize) {
            searchParams += "&pageSize=" + searchData.pageSize
        }
        if (searchData?.pageNumber) {
            searchParams += "&pageNumber=" + searchData.pageNumber
        }
        return axios.get(BLCUrl + resourceName + searchParams, { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
    get(id) {
        return axios.get(BLCUrl + resourceName + "/" + id, { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
    delete(id) {
        return axios.delete(BLCUrl + resourceName + "/" + id, { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
    getByUserCardNumber(cardNumber) {
        return axios.get(BLCUrl + resourceName + "/card/" + cardNumber, { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
    verify(requestBody) {
        return axios.post(BLCUrl + resourceName + "/verify", requestBody, { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
    verifyWithCard(requestBody) {
        return axios.post(BLCUrl + resourceName + "/verifyWithCard", requestBody, { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
    searchGroup(date, customerId) {
        let searchParams = "?"
        if (date) {
            searchParams += "&date=" + date
        }
        if (customerId) {
            searchParams += "&customerId=" + customerId
        }

        return axios.get(BLCUrl + resourceName + "/group" + searchParams, { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
    cancel(id, refund) {
        return axios.put(BLCUrl + resourceName + "/cancel/" + id + "/refund/" + refund, {}, { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
    saveWithCredit(requestBody) {
        return axios.post(BLCUrl + resourceName + "/credit", requestBody, { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
}
